import FetchClient from "../../../utils/fetchClient";
import React, {useEffect, useRef, useState} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useLayoutContext} from "../../../layout/layoutProvider";
import {useParams} from "react-router";
import chatPage from "../../chat/chatPage";
import {render} from "react-dom";
import WordCloud from "react-d3-cloud";
import Modal from "../../../components/modal";
import {createRoot} from "react-dom/client";
import {data} from "autoprefixer";
import { openCloseSidebarPanel } from "../../../utils/helpers";
import * as yup from 'yup';
import { Dialog } from "@mui/material";
import ReactQuill from "react-quill";
import { useAuth } from "../../../utils/authProvider";

const UserPage = (props) => {
    const location = useLocation()
    const {setHeader, setLoader} = useLayoutContext();
    const userWordcloudCanvasRef = useRef()
    const [errorsList, setErrorsList] = useState([]);
    const params = useParams();
    const userId = params.user_id;
    const loggedInfo = useAuth()
    const courseId = params.course_id;

    const [chats, setChats] = useState([]);
    const [chatsPagination, setChatsPagination] = useState({page: 0, total: 0, pageSize: 10, totalPages: [0]})

    const [user, setUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [infoModal, setInfoModal] = useState()
    const [sendMessageModal, setSendMessageModal] = useState(false);
    const [messageItem, setMessageItem] = useState({subject:"", body:"", reply_status:false, receivers_info:[]})

    const handleChangeItemsPerPage = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
        // setSearchValue("")
      };
    openCloseSidebarPanel(false);
    useEffect(()=>{
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setFilteredData(chats.slice(indexOfFirstItem, indexOfLastItem))
    },[currentPage]);
    useEffect(()=>{
        setPageNumbers([]); 

        for (let i = 1; i <= Math.ceil(chats?.length / itemsPerPage); i++) {
            setPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
          const indexOfLastItem = currentPage * itemsPerPage;
          const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          if(chats && chats.length > 0){
          setFilteredData(chats.slice(indexOfFirstItem, indexOfLastItem))}

    },[chats,itemsPerPage]);
    const getUserProfile = () => {
        FetchClient({
            method: 'get',
            url: `users/` + userId,
            data: {
                user_id: userId,
            },
            params:{
                course_id:courseId

            }
        }).then(response => {
            setUser(response.data);
            setHeader(response.data.name, response.data.email);
        })
    }
    const getChats = (page) => (
        FetchClient({
            method: 'get',
            url: 'teacher/course/'+courseId+'/student/'+userId+'/chats',
            params: {
                user_id: userId,
                course_id: courseId,
            }
        }).then(response => {
            setChats(response.data.items);
            setLoader(false);
        })
    );
    function getKeywords(chatId) {
        FetchClient({
            method: 'get',
            url: 'chat/' + chatId + '/keywords',
            params: {
                chat_id: chatId
            }
        }).then(response => {
            

            let keywords = [];
            for (let i = 0; i < response.data.length; i++) {
                keywords.push({text: response.data[i].title, value: response.data[i].proportion})
            }
            if (response.data.length > 0) {
                document.getElementById('no-chat-keyword').textContent = "";
                createRoot(document.getElementById('chat-wordcloud-canvas')).render(<WordCloud width={1000} height={400} data={keywords} font="Candara" fontWeight="bold" rotate={(word) => 0}/>);
            } else {
                document.getElementById('no-chat-keyword').textContent = "No Keyword Extracted!";
                createRoot(document.getElementById('chat-wordcloud-canvas')).render(<WordCloud width={1000} height={100} data={keywords} font="Candara" fontWeight="bold" rotate={(word) => 0}/>);
            }
        })
    }
    function getUserKeywords() {
        FetchClient({
            method: 'get',
            url: 'user/' + userId + '/keywords',
            params: {
                user_id: userId
            }
        }).then(response => {
            new Modal("#infoChatModal");
            let keywords = [];
            for (let i = 0; i < response.data.length; i++) {
                keywords.push({text: response.data[i].title, value: response.data[i].proportion})
            }
            if (keywords.length > 0) {
                document.getElementById('no-user-keyword').textContent = "";
                createRoot(document.getElementById('user-wordcloud-canvas')).render(<WordCloud width={1000} height={400} data={keywords} font="Candara" fontWeight="bold" rotate={(word) => 0}/>);
            } else {
                document.getElementById('no-user-keyword').textContent = "No Keyword Extracted!";
                createRoot(document.getElementById('user-wordcloud-canvas')).render(<WordCloud width={1000} height={100} data={keywords} font="Candara" fontWeight="bold" rotate={(word) => 0}/>);
            }
        })
    }
    useEffect(() => {
        setLoader(true);
        getUserProfile();
        getChats(chatsPagination.page);
        getUserKeywords();
        new Modal("#infoChatModal");
        new Modal("#updateUserRoleModal");
    }, [location]);
    useEffect(()=>{
        searchUser()
    },[searchValue])
    const searchUser=() =>{
        if(searchValue != ""){
            setPageNumbers([]);      
            setFilteredData(chats.filter(chat => chat.title.toLowerCase().includes(searchValue.toLowerCase())));

        for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
            setPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
        }
        else{
            setFilteredData(chats)
        }
    }
    const addMentorHandler = (event) =>{
        event.preventDefault();
        setLoader(true);
        FetchClient({
            method: 'post',
            url: '/course/human_teacher_assistants/add',
            data: {
                course_id: courseId,
                student_id: user.id,
            }
        }).then(response => {
            window.$notification({
                text: 'User added to mentors successfully!',
                variant: 'primary',
                position: 'center-top'
            })
            
            setLoader(false);
            window.location.reload();
        });

    }
    const removeMentorHandler = (event) =>{
        event.preventDefault();
        setLoader(true);
        FetchClient({
            method: 'post',
            url: '/course/human_teacher_assistants/remove',
            data: {
                course_id: courseId,
                student_id: user.id,
            }
        }).then(response => {
            window.$notification({
                text: 'User removed from mentors successfully!',
                variant: 'primary',
                position: 'center-top'
            })
            setLoader(false);
            window.location.reload();

        });


    }
    const keyUpHandler = (event) => {
        if (event.key === 'Enter') {
            postMessage();
        }
        return true;
    };
    const postMessage = async() =>{
      const schema = yup.object().shape({
        messageItem: yup.object().shape({
          body: yup.string().required("Body of message is required !"),
          subject: yup.string().required("subject of message is required !"),
        }),      
    });
    try {
        await schema.validate( {messageItem} , { abortEarly: false });  
        FetchClient({
          method: 'post',
          url: 'course/course_email/create',
          data: {
              course_id: courseId,
              sender_id: loggedInfo?.stores?.user?.id,
              sender_name:loggedInfo?.stores?.user?.name,
              subject: messageItem.subject,
              body: messageItem.body,
              receivers_info: [user]

          }
      }).then(response => {
        setMessageItem({subject:"", body:"", reply_status:false, receivers_info:[]});
        setSendMessageModal(false);
        window.$notification({
          text: 'Your message sent successfully !',
          variant: 'success',
          position: 'center-top'
      })
      })
    } catch (err) {
        setErrorsList(err.inner);
    }
    }
    const chatItems = filteredData.map((chat, index) => (
        <tr key={chat.id} className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td
                className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5"
            >
                <Link
                    to={"/user_list/user/" + userId + "/chat/" + chat.id}
                    data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                    data-active-class="font-medium text-primary dark:text-accent-light"
                    className={"flex-1 nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                    + (chat.unread_messages_count>0?" font-bold":"")}
                >
                    {chat.title}
                    <div className={"-mt-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent"
                        + (chat.unread_messages_count>0?"":" hidden")}
                    >
                        {chat.unread_messages_count}
                    </div>
                </Link>
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                <img
                    className="rounded-full h-8 w-8"
                    src={process.env.PUBLIC_URL + '/images/assistant/assistant_' + chat.assistant_id + '.jpg'}
                    alt="avatar"
                />
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                <button
                    className="flex darkmode-toggle btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    data-toggle="modal"
                    data-target="#infoChatModal"
                    id={"info" + chat.id}
                    onClick={(event) => {
                        getKeywords(chat.id);
                    }}
                >
                    <img
                        className="mx-auto w-4 h-4"
                        src={process.env.PUBLIC_URL + '/images/buttons/info.png'}
                        alt="info"
                    />
                </button>
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                {chat.usage_prompt_tokens + chat.usage_completion_tokens}
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                {chat.type === 'exercise' ?
                    <div
                        className="badge rounded-full bg-secondary/10 text-secondary dark:bg-secondary-light/15 dark:text-secondary-light">
                        {chat.type}
                    </div>
                    :
                    <div className="badge rounded-full bg-info/10 text-info dark:bg-info/15">
                        {chat.type}
                    </div>
                }
            </td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                {new Date(chat.creation_date).toLocaleString(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    weekday: "long",
                    hour: '2-digit',
                    hour12: false,
                    minute: '2-digit',
                    second: '2-digit'
                })}
            </td>
        </tr>)
    );
    return <main className="main-content w-full px-[var(--margin-x)] pb-8 pt-4">
        <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                User Page
            </h2>
        </div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
            <div className="card grow items-center p-4 text-center sm:p-5 col-span-12 lg:col-span-4 xl:col-span-3">
                <div className="avatar h-18 w-18">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-18 w-18"
                        fill="none"
                        viewBox="0 0 48 48">
                        <g fill="#FFA726">
                            <circle cx="10" cy="26" r="4"/>
                            <circle cx="38" cy="26" r="4"/>
                        </g>
                        <path fill="#FFB74D"
                              d="M39,19c0-12.7-30-8.3-30,0c0,1.8,0,8.2,0,10c0,8.3,6.7,15,15,15s15-6.7,15-15C39,27.2,39,20.8,39,19z"/>
                        <path fill="#FF5722"
                              d="M24,3C14.6,3,7,10.6,7,20c0,1.2,0,3.4,0,3.4L9,25v-3l21-9.8l9,9.8v3l2-1.6c0,0,0-2.1,0-3.4 C41,12,35.3,3,24,3z"/>
                        <g fill="#784719">
                            <circle cx="31" cy="26" r="2"/>
                            <circle cx="17" cy="26" r="2"/>
                        </g>
                        <path fill="#757575"
                              d="M43,24c-0.6,0-1,0.4-1,1v-7c0-8.8-7.2-16-16-16h-7c-0.6,0-1,0.4-1,1s0.4,1,1,1h7c7.7,0,14,6.3,14,14v10 c0,0.6,0.4,1,1,1s1-0.4,1-1v2c0,3.9-3.1,7-7,7H24c-0.6,0-1,0.4-1,1s0.4,1,1,1h11c5,0,9-4,9-9v-5C44,24.4,43.6,24,43,24z"/>
                        <g fill="#37474F">
                            <path
                                d="M43,22h-1c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h1c1.1,0,2-0.9,2-2v-4C45,22.9,44.1,22,43,22z"/>
                            <circle cx="24" cy="38" r="2"/>
                        </g>
                    </svg>
                </div>
                <div className="my-2 grow flex flex-col items-center">
                    <h3
                        className="text-base font-medium text-slate-700 dark:text-navy-100"
                    >
                        {user.name}
                    </h3>
                    <div className="flex justify-center items-center">
                        <p 
                           className="text-xs+ text-primary hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                            {user.user_role === "human_teacher_assistant" ? "teacher assistant" : user.user_role}
                        </p>&nbsp;
                        {user.user_role === "teacher" || user.user_role === "admin" ? "" : 
                        <button
                            className="flex  btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                            data-toggle="modal"
                            data-target="#updateUserRoleModal"
                            id={"role" + user.id}
                        >
                            <img
                                className=" w-4 h-4"
                                src={process.env.PUBLIC_URL + '/images/buttons/edit.png'}
                                alt="edit"
                            />
                        </button>
                        }
                    </div>
                    <button
                        className="btn h-7 mt-2 rounded-full bg-slate-150 px-3 text-xs+ font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90">
                        {user.email}
                    </button>
                    <button 
                      onClick={()=> setSendMessageModal(true)}
                    className={"btn mt-2 flex justify-between h-4 w-32 p-5 whitespace-nowrap bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 "}>
                        <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8 11H8.01M12 11H12.01M16 11H16.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#ffffff" 
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                        <span>
                            Message
                        </span>
                    </button>
                </div>
                <div className="mt-3 flex space-x-1">
                </div>
            </div>

            <div className="col-span-12 lg:col-span-8 xl:col-span-9">
                <div className="flex items-center justify-between">
                    <h2
                        className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100"
                    >
                        User Chat List
                    </h2>
                    <div className="flex">
                        <div className="table-search-wrapper flex items-center">
                            <label className="block">
                          
                            <input
                                    className="table-search-input form-input w-0 bg-transparent px-1 text-right transition-all duration-100 placeholder:text-slate-500 dark:placeholder:text-navy-200"
                                    placeholder="Search here..."
                                    type="text"
                                    value={searchValue}
                                    onChange={(e)=> setSearchValue(e.target.value)}
                                />
                            </label>
                            <button
                                className="table-search-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4.5 w-4.5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                        <table className="is-hoverable w-full text-left">
                            <thead>
                            <tr>
                                <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    #
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Title
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Assistant
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Info
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Tokens
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Type
                                </th>
                                <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Creation Date
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {chatItems}
                            </tbody>
                        </table>
                    </div>

                    <div
                        className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5"
                    >
                        <div className="flex items-center space-x-2 text-xs+">
                            <span>Show</span>
                            <label className="block">
                                <select value={itemsPerPage} onChange={(e) => handleChangeItemsPerPage(parseInt(e.target.value))}
                                        className="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
                                    <option>10</option>
                                    <option>20</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </label>
                            <span>entries</span>
                        </div>

                        <ol className="pagination">
                        <li className="rounded-l-lg bg-slate-150 dark:bg-navy-500">
                            <a
                                href="#"
                                onClick={(event) => (currentPage > 1 ? 
                                setCurrentPage((prev)=>prev - 1) : event.preventDefault())}       
                                className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                            </a>
                        </li>
                        {pageNumbers.map((page, index) => (
                            <li key={index} className="bg-slate-150 dark:bg-navy-500">
                                <a href="#" onClick={() => setCurrentPage(page)}
                                   className={(page != currentPage ? "flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                           : "flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90")
                                       + ((page > currentPage + 2) && (page != Math.ceil(chats?.length / itemsPerPage) - 1) ? " hidden" : "")
                                       + ((page < currentPage - 2) && (page != 0) ? " hidden" : "")}>
                                    {page}
                                </a>
                                <a href="#"
                                   className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                       + (((page < currentPage - 2) && (page == 1)) ? " " : " hidden")}>
                                    {"..."}
                                </a>
                                <a href="#"
                                   className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                       + (((page > currentPage + 2) && (page == Math.ceil(chats.length / itemsPerPage) - 2)) ? " " : " hidden")}>
                                    {"..."}
                                </a>
                            </li>)
                        )}
                        <li className="rounded-r-lg bg-slate-150 dark:bg-navy-500">
                            <a
                                href="#"
                                onClick={(event) => (currentPage < (Math.ceil(chats.length / itemsPerPage) ) ?
                                setCurrentPage((prev)=>prev + 1) :
                                  event.preventDefault())}                                
                                  className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </a>
                        </li>
                    </ol>

                        <div
                         className="text-xs+">{(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData?.length} entries
                        </div>                        
                    </div>
                </div>
            </div>
        </div>

        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 mt-16">
            <div className="col-span-12">
                <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                    User Keywords
                </h2>
            </div>
            <div className="card col-span-12">
                <div id="no-user-keyword"></div>
                <div id="user-wordcloud-canvas" className="w-full" ref={userWordcloudCanvasRef}></div>
            </div>
        </div>

        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="infoChatModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex w-[80%] flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/info.png'}
                    alt="logo"
                />

                <div className="w-full mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Keywords
                    </h2>
                    <div id="no-chat-keyword"></div>
                    <div id="chat-wordcloud-canvas" className="w-full"></div>
                    <div className="space-x-2 text-right mt-4">
                        <button
                            data-close-modal
                            onClick={(event) => event.preventDefault()}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            OK
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => event.preventDefault()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="updateUserRoleModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <div className="mt-4">
                    <h6 className="text-2xl text-slate-700 dark:text-navy-100">
                        User role
                    </h6>
                        {user.user_role === "human_teacher_assistant" ?
                    <p className="mt-2">
                        Remove this student from assistants list
                    </p>
                    :
                    <p className="mt-2">
                     Add this student to assistants list
                    </p>
                        }
                    <div className="space-x-2 text-center mt-4">
                        {user.user_role === "human_teacher_assistant" ?
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full font-medium text-white bg-error-focus"
                            onClick={(event) => removeMentorHandler(event)}
                        >
                             Remove
                        </button>
                        :
                        <button
                            data-close-modal
                            onClick={(event) => addMentorHandler(event)}
                            className="btn min-w-[7rem] rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-green-focus active:bg-success-focus/90 dark:bg-green dark:hover:bg-green-focus dark:focus:bg-green-focus dark:active:bg-green/90"
                        >
                            Add
                        </button>}
                    </div>
                </div>
            </div>
        </div>
        <Dialog
                open={sendMessageModal}
                onClose={()=>setSendMessageModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div
                    className="modal-content relative flex w-full flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                            Compose your message
                        </h3>
                        <button
                            onClick={() => setSendMessageModal(false)}
                            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4.5 w-4.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5"
                        onKeyUp={(event) => keyUpHandler(event)}>                     
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Subject:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Your Message Title"
                                    name="title"
                                    value={messageItem.subject}
                                    onChange={(e) => setMessageItem(messageItem=> ({
                                      ...messageItem,
                                      subject:e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.subject")?.[0]?.errors?.[0]}</span>
                            </label>
                            <label className="block">
                                <span>body:</span>
                                <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                  value={messageItem.body}
                                  onChange={(data)=> setMessageItem(messageItem=> ({
                                    ...messageItem,
                                    body:data.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !data.includes("<img") ? "" : data
                                  }))}
                                />
                                <span className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.body")?.[0]?.errors?.[0]}</span>
                            </label>

                        </div>
                        <label className="flex p-4">
                            <span>Can student reply?</span>
                            <input className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                type="checkbox"
                                onChange={(e) => setMessageItem(messageItem=> ({
                                  ...messageItem,
                                  reply_status:e.target.checked}))}
                            />
                                                
                        </label>
                    </form>
                    <div className="space-x-2 p-3 text-right">
                        <button
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setSendMessageModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={()=> postMessage()}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Send
                        </button>
                    </div>
                </div>                  
            </Dialog>
    </main>;
}

export default UserPage;
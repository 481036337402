import JoinNowImage from "../../../../../images/join_now.webp";

const JoinMessageContainer = () => {
    return (
        <>
            <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid md:grid-cols-7 gap-4 md:gap-8 xl:gap-20 md:items-center">
            <div class="md:col-span-4">
                        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white text-gray-800 mb-8">
                            Join JOURNiCRAFT Now
                        </h2>
                        <p className="text-xl dark:text-gray-500 text-slate-800">
                            Get started today and join the JOURNiCRAFT newsletter of teachers revolutionizing education
                            with
                            generative AI. Sign up for your free account and start creating engaging courses that
                            inspire
                            your
                            students to learn and grow.
                        </p>
                        <a className="py-3 px-4 mt-8 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-jclogo-400 text-white hover:bg-jclogo-600 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                           href="/login">
                            Join Now
                            <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path d="m9 18 6-6-6-6"/>
                            </svg>
                        </a>
                    </div>
                    <div class="md:col-span-3 relative ms-4">
                        <img
                            src={JoinNowImage}
                            alt="JOURNiCRAFT"
                            className="w-full rounded-xl"                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default JoinMessageContainer;
import React, {useEffect, useState} from "react";

import {useLayoutContext} from "../../layout/layoutProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {openCloseSidebarPanel} from "../../utils/helpers";
import FetchClient from "../../utils/fetchClient";

import Badge from "./badge";
import ButtonCard from "../../components/button_card";
import ChatButtonCard from "../../components/chat_button_card";
import SimpleChat from "./simpleChat";
import ModelChat from "./modelChat";
import {useCourseContext} from "../../utils/courseContext";
import AppPreloader from "../../layout/preloader";
import { useAuth } from "../../utils/authProvider";

const DashboardPage = (props) => {
    const navigate = useNavigate();
    const store = useAuth()
    const location = useLocation()
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [profile, setProfile] = useState({
        general_chat_number: 0, exercise_chat_number: 0, usage_prompt_tokens: 0, usage_completion_tokens: 0
    });
    const [badge, setBadge] = useState();
    const [wizardTypesList, setWizardTypesList] = useState([]);
    const [selectedWizard, setSelectedWizard] = useState({});
    const [questions, setQuestions] = useState([]);
    const [assistant, setAssistant] = useState({});
    const [generalAssistants, setGeneralAssistants] = useState([]);
console.log("props", store);
    function updateMenu() {
        if (location.pathname.includes('dashboard')) {
            setSidebarSelectedMenuItem('dashboard');
        }
    }

    const getWizardTypesList = () => {
        FetchClient({
            method: 'get',
            url: `chat/wizard/types`,

        }).then(response => {
            setWizardTypesList(response?.data?.items)
        })
    }

    const getUserProfile = () => {
        FetchClient({
            method: 'get',
            url: `profile`
        }).then(response => {
            setProfile(response.data);

            localStorage.setItem("user", response.data)
            if (store?.stores?.course?.role === "student" && store?.stores?.course.status === "subscribed") {
                getStudentBadge(response.data?.id);
            }
        })
    }
    const getStudentBadge = (id) => {
        FetchClient({
            method: 'get',
            url: `course/student/badge`,
            params: {
                course_id: store?.stores?.course?.id,
                user_id: id
            }
        }).then(response => {
            setBadge(response.data)
        })
    }
    useEffect(() => {
        setLoader(false);
        updateMenu();
        openCloseSidebarPanel(false);
        getUserProfile();
        getWizardTypesList();
        getGeneralAssistants();
    }, [location]);

    const getWizardQuestions = (context) => {
        FetchClient({
            method: 'get',
            url: `chat/wizard/questions`,
            params: {
                chat_wizard_id: context?.id,
            }
        }).then(response => {
            setQuestions(response.data)
        })
    }
    const handleSelectWizard = (context) => {
        if (context === "general") {
            setSelectedWizard({is_wizard: false});
            getAssistant(context);
        } else {
            setSelectedWizard(context);
            getWizardQuestions(context);
            getAssistant(context);
        }
    }
    const getGeneralAssistants = () => (
        FetchClient({
            method: 'get',
            url: 'assistants',
            params: {}
        }).then(response => {
            setGeneralAssistants(response.data);
        })
    );
    const getAssistant = (context) => (
        FetchClient({
            method: 'get',
            url: '/assistants'
        }).then(response => {
            setAssistant(response?.data.filter((item) => item.id === context?.assistant_id)[0])
        })
    );
    const getAssistantInfo = (this_assistant_id) => {
        return generalAssistants?.filter((item) => item.id === this_assistant_id)[0];
    }
    return (
        <main className="main-content h-100vh mt-0">
            <div
                className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-14 transition-all duration-[.25s]">
                {store?.stores?.course ? 
                store?.stores?.course?.status !== 'requested' ? "" :
                    <div
                        className="mt-4 p-2 w-auto h-auto rounded-md text-center bg-red-800 text-slate-300 dark:text-slate-300">
                        You are not accepted into a course. You will have access the JOURNiCRAFT tools when your teacher
                        accepts your request.
                        After that, you can see chat icons in the left sidebar.
                    </div>
                    :
                    ""
                }
                {(store?.stores?.course?.role === "student" && store?.stores?.course?.status === "subscribed") ?
                    <div>
                        <div className="mt-8">
                            <h1 className="text-2xl">
                                Hi <span
                                className="text-emerald-800 dark:text-emerald-500 font-bold">{profile.name}</span>,
                                <br/>
                                Welcome to our AI-powered teaching assistant platform, presented by JOURNiCRAFT.
                            </h1>
                            <br/>
                            <h1 className="text-xl">
                                Please use the <span className="font-bold">sidebar</span> to start a chat!
                            </h1>
                        </div>
                        {badge && (badge?.number_of_chats % 5 === 0 || badge?.number_of_messages === 10 || badge?.number_of_messages === 25 || badge?.number_of_messages === 50) && badge?.number_of_chats > 0 ?
                            <div>
                                <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"/>
                                <div className={`m-4`}>
                                    <h1 className="text-xl font-bold">
                                        Achievements
                                    </h1>
                                    <div className=" flex mt-4">
                                        {badge?.number_of_chats && badge?.number_of_chats % 5 === 0 &&
                                            <Badge color={"bg-blue-200"}
                                                   content={`You have set a new record with an impressive ${badge?.number_of_chats} chats. Keep up the great work!`}
                                                   icon={
                                                       <svg width="45px" height="45px" viewBox="0 0 1024 1024"
                                                            class="icon" version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#000000">
                                                           <g id="SVGRepo_bgCarrier"
                                                              strokeWidth="0"></g>
                                                           <g id="SVGRepo_tracerCarrier"
                                                              strokeLinecap="round" strokeLinejoin="round"></g>
                                                           <g id="SVGRepo_iconCarrier">
                                                               <path
                                                                   d="M1001 500.5c0 234.6-219.2 424.8-489.7 424.8-70.2
                                0-136.9-12.8-197.3-35.9-25.1-9.6-129.6 72.9-152.3 60-25.3-14.5 31.5-124.8 9.7-143C79.1 729.1 21.7 620.6 21.7 500.5c0-234.6 219.2-424.8 489.7-424.8S1001 265.9 1001 500.5z"
                                                                   fill="#FAFCFB"></path>
                                                               <path
                                                                   d="M168 964.3c-4.8 0-9.1-1-12.9-3.2-16.4-9.4-13.6-32.5-12.7-40.1 1.7-14 6.2-31 10.5-47.4 3.5-13.4 7.2-27.3 9-38.6 2.2-13.2 0.9-17.7 0.6-18.7-48.2-40.3-86.1-87.7-112.6-140.8-27.7-55.3-41.7-114.2-41.7-175 0-59.4 13.4-117 39.8-171.3 25.5-52.2 61.9-99.1 108.2-139.3 46.2-40.1 100-71.6 159.8-93.5 61.9-22.7 127.6-34.2 195.3-34.2s133.4 11.5 195.3 34.2c59.9 22 113.6 53.4 159.8 93.5 46.4 40.2 82.8 87.1 108.2 139.3 26.4 54.2 39.8 111.9 39.8 171.3 0 59.4-13.4 117-39.8 171.3-25.5 52.2-61.9 99.1-108.2 139.3-46.2 40.1-100 71.6-159.8 93.5-61.9 22.7-127.6 34.2-195.3 34.2-70.2 0-138-12.3-201.8-36.7-1-0.1-6.3-0.1-22.4 7.3-12.3 5.7-27.2 13.9-41.6 21.9-36 20.1-60.3 33-77.5 33zM511.3 89.2c-64.5 0-127.1 11-185.9 32.6-56.8 20.8-107.7 50.6-151.5 88.6-43.6 37.7-77.8 81.7-101.6 130.6-24.6 50.5-37.1 104.2-37.1 159.4 0 56.6 13.1 111.4 38.9 163C99 713.4 134.7 758 180.2 796c17.2 14.4 8.7 46.9-1.2 84.5-3.6 13.5-7.2 27.6-9.1 39-1.7 10.6-1.3 15.7-0.8 17.8 2.6-0.2 8.7-1.5 21.3-7.3 12.5-5.7 27.6-14.1 42.1-22.2 43.6-24.2 69.1-37.6 86.5-30.9 60.7 23.2 125.5 35 192.4 35 64.5 0 127.1-11 185.9-32.6 56.8-20.8 107.7-50.6 151.5-88.6 43.6-37.8 77.8-81.8 101.7-130.8 24.6-50.5 37.1-104.2 37.1-159.4 0-55.3-12.5-108.9-37.1-159.4-23.9-48.9-58.1-92.9-101.7-130.8-43.7-37.9-94.7-67.7-151.5-88.6-58.9-21.6-121.5-32.5-186-32.5z"
                                                                   fill="#0F0F0F"></path>
                                                               <path
                                                                   d="M717.4 405.1m-46.6 0a46.6 46.6 0 1 0 93.2 0 46.6 46.6 0 1 0-93.2 0Z"
                                                                   fill="#141414"></path>
                                                               <path
                                                                   d="M345.7 442c-7.5 0-13.5-6-13.5-13.5 0-18.3-13.5-33.2-30-33.2s-30 14.9-30 33.2c0 7.5-6 13.5-13.5 13.5s-13.5-6-13.5-13.5c0-33.2 25.6-60.2 57-60.2s57 27 57 60.2c0 7.4-6.1 13.5-13.5 13.5z"
                                                                   fill="#141414"></path>
                                                               <path
                                                                   d="M396.2 532.7c0 60.1 51.8 109.2 115.2 109.2 63.3 0 115.2-49.1 115.2-109.2v-27.9H396.2v27.9z"
                                                                   fill="#4F423B"></path>
                                                               <path
                                                                   d="M511.3 655.4c-70.9 0-128.7-55.1-128.7-122.7v-41.4H640v41.4c0 67.7-57.7 122.7-128.7 122.7zM409.7 518.3v14.4c0 52.8 45.6 95.7 101.7 95.7s101.7-42.9 101.7-95.7v-14.4H409.7z"
                                                                   fill="#141414"></path>
                                                               <path
                                                                   d="M443.2 588.6a68.1 27.1 0 1 0 136.2 0 68.1 27.1 0 1 0-136.2 0Z"
                                                                   fill="#D39E33"></path>
                                                               <path
                                                                   d="M175.9 561.8m-42.5 0a42.5 42.5 0 1 0 85 0 42.5 42.5 0 1 0-85 0Z"
                                                                   fill="#9DC6AF"></path>
                                                               <path
                                                                   d="M853.4 561.8m-42.5 0a42.5 42.5 0 1 0 85 0 42.5 42.5 0 1 0-85 0Z"
                                                                   fill="#9DC6AF"></path>
                                                           </g>
                                                       </svg>}/>
                                        }
                                        {(badge?.number_of_messages === 10 || badge?.number_of_messages === 25 || badge?.number_of_messages === 50) &&
                                            <Badge color={"bg-green-200"}
                                                   content={`You have received a new record number of messages - a total of ${badge?.number_of_messages} !`}
                                                   icon={<svg width="45px" height="45px" viewBox="0 0 1024 1024"
                                                              class="icon" version="1.1"
                                                              xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                                       <g
                                                           id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                       <g id="SVGRepo_tracerCarrier"
                                                          strokeLinecap="round" strokeLinejoin="round"></g>
                                                       <g id=
                                                              "SVGRepo_iconCarrier">
                                                           <path
                                                               d="M964.6 202.2L599.8 528.7c-48.4 43.3-121.6 43.3-169.9 0L65 202.2c-19 13.5-31.5 35.5-31.5 60.6v507c0 41.1 33.3 74.4 74.4 74.4h813.6c41.1 0 74.4-33.3 74.4-74.4v-507c0.2-25.1-12.3-47.1-31.3-60.6z"
                                                               fill="#9DC6AF"></path>
                                                           <path
                                                               d="M921.7 857H108c-48.1 0-87.2-39.1-87.2-87.2v-507c0-28.1 13.8-54.6 36.8-71l8.3-5.9 372.5 333.3c43.6 39 109.3 39 152.8 0l372.5-333.3 8.3 5.9c23.1 16.4 36.8 43 36.8 71v507c0.1 48.1-39 87.2-87.1 87.2zM64.8 219.1c-11.6 11.5-18.3 27.2-18.3 43.7v507c0 34 27.6 61.6 61.6 61.6h813.6c34 0 61.6-27.6 61.6-61.6v-507c0-16.4-6.7-32.2-18.3-43.7L608.4 538.2c-53.3 47.7-133.7 47.7-187 0L64.8 219.1z"
                                                               fill="#191919"></path>
                                                           <path
                                                               d="M429.9 503.9L65 830.4c12.2 8.6 26.9 13.8 43 13.8h813.6c16.1 0 30.8-5.2 43-13.8L599.8 503.9c-48.4-43.3-121.5-43.3-169.9 0z"
                                                               fill="#FAFCFB"></path>
                                                           <path
                                                               d="M921.7 857H108c-18.1 0-35.5-5.6-50.4-16.2-3.2-2.3-5.2-5.8-5.4-9.7-0.2-3.9 1.3-7.7 4.2-10.3l364.8-326.5c53.3-47.7 133.7-47.7 187 0L973 820.8c2.9 2.6 4.5 6.4 4.2 10.3-0.2 3.9-2.2 7.5-5.4 9.7-14.6 10.6-32.1 16.2-50.1 16.2zM87.2 827.7c6.6 2.4 13.7 3.7 20.8 3.7h813.6c7.1 0 14.1-1.3 20.8-3.7L591.3 513.4c-43.6-39-109.3-39-152.8 0L87.2 827.7z"
                                                               fill="#0F0F0F">
                                                           </path>
                                                           <path
                                                               d="M599.8 579.9l364.8-375.6c-12.2-9.9-26.9-15.9-43-15.9H108c-16.1 0-30.8 6-43 15.9l364.8 375.6c48.5 49.8 121.6 49.8 170 0z"
                                                               fill="#FAFCFB"></path>
                                                           <path
                                                               d="M514.8 630.1c-34.9 0-68.3-14.6-94.2-41.2L55.9 213.2c-2.5-2.6-3.8-6.1-3.6-9.7 0.2-3.6 1.9-6.9 4.7-9.2 15-12.3 32.7-18.8 51.1-18.8h813.6c18.4 0 36 6.5 51.1 18.8 2.8 2.3 4.5 5.6 4.7 9.2s-1.1 7.1-3.6 9.7L609 588.8c-25.8 26.6-59.3 41.3-94.2 41.3zM85.1 206.5l354 364.5c21 21.6 47.9 33.5 75.8 33.5s54.8-11.9 75.8-33.5l354-364.5c-7.3-3.5-15-5.4-22.9-5.4H108c-7.9 0.1-15.6 1.9-22.9 5.4z"
                                                               fill="#0F0F0F"></path>
                                                           <path
                                                               d="M260.1 351.7c0-24.6 17.2-44.8 38.2-44.8 21 0 38.2 20.1 38.2 44.8"
                                                               fill="#FAFCFB"></path>
                                                           <path
                                                               d="M336.6 364.5c-7.1 0-12.8-5.7-12.8-12.8 0-17.3-11.6-32-25.4-32-13.8 0-25.4 14.6-25.4 32 0 7.1-5.7 12.8-12.8 12.8-7.1 0-12.8-5.7-12.8-12.8 0-31.8 22.9-57.6 51.1-57.6 28.2 0 51.1 25.8 51.1 57.6-0.2 7.1-6 12.8-13 12.8z"
                                                               fill="#0F0F0F"></path>
                                                           <path
                                                               d="M693.1 351.7c0-24.6 17.2-44.8 38.2-44.8 21 0 38.2 20.1 38.2 44.8"
                                                               fill="#FAFCFB"></path>
                                                           <path
                                                               d="M769.6 364.5c-7.1 0-12.8-5.7-12.8-12.8 0-17.3-11.6-32-25.4-32-13.8 0-25.4 14.6-25.4 32 0 7.1-5.7 12.8-12.8 12.8s-12.8-5.7-12.8-12.8c0-31.8 22.9-57.6 51.1-57.6s51.1 25.8 51.1 57.6c-0.2 7.1-5.9 12.8-13 12.8z"
                                                               fill="#0F0F0F"></path>
                                                           <path
                                                               d="M419.7 383.4c0 46.3 42.8 84.2 95.1 84.2s95.1-37.9 95.1-84.2v-28.1H419.7v28.1z"
                                                               fill="#5B5143"></path>
                                                           <path
                                                               d="M514.8 480.4c-59.5 0-108-43.5-108-97v-28.1c0-7.1 5.7-12.8 12.8-12.8H610c7.1 0 12.8 5.7 12.8 12.8v28.1c0 53.5-48.4 97-108 97z m-82.3-112.3v15.3c0 18.7 8.4 36.4 23.6 49.9 15.7 13.9 36.5 21.5 58.7 21.5 22.2 0 43-7.6 58.7-21.5 15.2-13.5 23.6-31.2 23.6-49.9v-15.3H432.5z"
                                                               fill="#141414"></path>
                                                           <path
                                                               d="M458.6 413a54.7 27.6 0 1 0 109.4 0 54.7 27.6 0 1 0-109.4 0Z"
                                                               fill="#D39E33"></path>
                                                       </g>
                                                   </svg>}/>
                                        }

                                    </div>
                                </div>
                            </div> : ""
                        }
                        <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"/>
                        <div className="m-4">
                            <h1 className="text-xl font-bold">
                                User Data
                            </h1>
                        </div>
                        <div className="mt-4 w-full text-center">
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-4 lg:gap-6">
                                <div className="card flex-row justify-between p-4">
                                    <div>
                                        <p className="text-xs+ uppercase">Chats</p>
                                        <div className="mt-8 flex items-baseline space-x-1">
                                            <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                                                {profile.general_chat_number}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="mask is-squircle flex h-10 w-10 items-center justify-center bg-warning/10">
                                        <i className="fa-solid fa-comments text-xl text-warning"></i>
                                    </div>
                                    <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                                        <i className="fa-solid fa-comments translate-x-1/4 translate-y-1/4 text-5xl opacity-15"></i>
                                    </div>
                                </div>
                                <div className="card flex-row justify-between p-4">
                                    <div>
                                        <p className="text-xs+ uppercase">Exercise Chats</p>
                                        <div className="mt-8 flex items-baseline space-x-1">
                                            <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                                                {profile.exercise_chat_number}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="mask is-squircle flex h-10 w-10 items-center justify-center bg-info/10">
                                        <i className="fa-solid fa-newspaper text-xl text-info"></i>
                                    </div>
                                    <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                                        <i className="fa-solid fa-newspaper translate-x-1/4 translate-y-1/4 text-5xl opacity-15"></i>
                                    </div>
                                </div>
                                <div className="card flex-row justify-between p-4">
                                    <div>
                                        <p className="text-xs+ uppercase">Total Tokens</p>
                                        <div className="mt-8 flex items-baseline space-x-1">
                                            <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                                                {(profile.usage_prompt_tokens + profile.usage_completion_tokens).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="mask is-squircle flex h-10 w-10 items-center justify-center bg-error/10">
                                        <i className="fa-solid fa-eye text-xl text-error"></i>
                                    </div>
                                    <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                                        <i className="fa-solid fa-eye translate-x-1/4 translate-y-1/4 text-5xl opacity-15"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    (store?.stores?.course?.role === "teacher" || store?.stores?.course?.role === "human_teacher_assistant" && store?.stores?.course?.status === "subscribed")
                        ?
                        <div className="grid grid-cols-12 gap-1 mt-4">
                        <div className="flex flex-col h-[calc(100vh-6rem)] col-span-12 md:col-span-5">
                            <div className="scrollbar-sm grow overflow-y-auto pr-2">
                                <div className="grid grid-cols-2 gap-1">
                                    <div className={"col-span-2"} onClick={() => {
                                        // handleSelectWizard("general")
                                        navigate("/chats");
                                    }}>
                                        <ButtonCard color={"bg-gray-300"} title={"Your Chat List"}/>
                                    </div>
                                    {wizardTypesList.map((context, index) => {
                                        return (
                                            <div className={`${context?.is_wizard ? "" : ""} col-span-1 h-full`}
                                                 onClick={() => {
                                                     handleSelectWizard(context)
                                                 }}>
                                                <ChatButtonCard key={index} selected={selectedWizard?.id === context.id}
                                                                bg_color={(index % 5 === 1 ? "bg-colorVariants-lime_200" :
                                                                    index % 5 === 2 ? "bg-colorVariants-fuchsia_200" :
                                                                        index % 5 === 3 ? "bg-colorVariants-red_200" :
                                                                            index % 5 === 4 ? "bg-colorVariants-amber_200" :
                                                                                "bg-colorVariants-blue_200")}
                                                                title={context.name} description={context.description}
                                                                image={getAssistantInfo(context.assistant_id)?.image_file_url}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {selectedWizard.is_wizard === true ?
                            <div key={selectedWizard.id}
                                 className="col-span-12 md:col-span-7">
                                <ModelChat userProfile={profile} selectedCourse={store?.stores?.course}
                                           assistant={assistant}
                                           wizard={selectedWizard} questions={questions}/>
                            </div>
                            :
                            selectedWizard.is_wizard === false ?
                                <div key={selectedWizard.id}
                                     className="col-span-12 md:col-span-7">
                                    <SimpleChat userProfile={profile} course={store?.stores?.course}
                                                wizard={selectedWizard} assistants={generalAssistants}/>

                                </div>
                                :
                                <div class="col-span-12 justify-center md:col-span-7 h-fit m-12 alert flex rounded-full bg-primary/10 py-4 px-4 text-primary dark:bg-accent-light/15 dark:text-accent-light sm:px-5">
                                    Select one of the chat topics to continue.
                                </div>
                        }

                        </div>
                    : ""
                    }

                
            </div>
        </main>
    );
}

export default DashboardPage;
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useAuth} from "../../utils/authProvider";

import whiteLogoImage from '../../../images/jclogow.svg';
import googleIcon from '../../../images/google.png';
import githubIcon from '../../../images/github.png';
import FetchClient from "../../utils/fetchClient";
import Application from "../../app";
import {useLayoutContext} from "../../layout/layoutProvider";
import Modal from "../../components/modal";
import Popper from "../../components/popper";
import { useCourseContext } from '../../utils/courseContext';
import { useCookies } from 'react-cookie';
import Cookies from "js-cookie";
import {set_cookies_user} from "../../utils/cookieHandler";

const logoColor = "#21cbaf";

const LoginPage = () => {
    const {setStore} = useAuth();
    const navigate = useNavigate();
    const {setLoader} = useLayoutContext();
    const { setSelectedCourse, setUser } = useCourseContext();
    // const [cookies, setCookie, removeCookie] = useCookies();

    const [response_error, setResponseError] = useState({message: "", customized_error_code: -1});
    const [inputs, setInputs] =
        useState({username: "", password: ""});

    const [forget_password_response_error, setForgetPasswordResponseError] = useState({
        message: "",
        customized_error_code: -1
    });
    const [emailInput, setEmailInput] = useState({email: ""});
    const [forgetPasswordModal, setForgetPasswordModal] = useState();

    const [send_activation_response_error, setSendActivationResponseError] = useState({
        message: "",
        customized_error_code: -1
    });
    const [sendActivationEmailModal, setSendActivationEmailModal] = useState();

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInputs(values => ({...values, [name]: value}))
    }
    const handleLogin = (event) => {
        event.preventDefault();
        setLoader(true);
        FetchClient({
            method: 'post',
            url: 'login',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                username: inputs.username,
                password: inputs.password
            }
        }).then(response => {
            setResponseError({message: "", customized_error_code: -1});

            const tokens = response.data;
            setStore("token", tokens.access_token);

            FetchClient({
                method: 'get',
                url: `profile`,
                data: {}
            }).then(response => {
                setResponseError({message: "", customized_error_code: -1});

                const user = response.data;
                setStore("user", response.data);
                // Changed to handle cookiesss
                set_cookies_user(response.data);
                // localStorage.setItem("user_role", response?.data?.user_role)
                setUser(user);
                FetchClient({
                    method: 'get',
                    url: 'user/' + user.id + '/courses',
                    params: {
                        user_id: user.id
                    }
                }).then(response => {
                    var coursesList = response.data;
                    if (coursesList && coursesList.length > 0) {
                        setStore("course", coursesList[0]);
                        setSelectedCourse(coursesList[0])
                    }
                    navigate("/", {replace: true});
                }).catch((error) => {
                });
            })
        }).catch((error) => {
            setLoader(false);
            if (error.response) {
                setResponseError(error.response.data.detail);
            }
        })
    };
    const handleGoogleLogin = () => {
        navigate('/api/oauth2/google', {replace: true});
    };

    const openForgetPasswordModal = (event) => {
        event.preventDefault();
        setEmailInput({email: ""});
        forgetPasswordModal.open();
    }
    const handleEmailChangeInput = (event) => {
        const {name, value} = event.target;
        setEmailInput(values => ({...values, [name]: value}))
    }

    function submitForgetPassword(event) {
        event.preventDefault();
        setLoader(true);
        FetchClient({
            method: 'get',
            url: 'forget_password/',
            params: {
                email: emailInput.email
            }
        }).then(response => {
            window.$notification({
                text: 'Check your email, please. A link to set new password has been send to your email!',
                variant: 'success',
                position: 'center-top'
            })
            setLoader(false);
            forgetPasswordModal.close();
        }).catch((error) => {
            setLoader(false);
            if (error.response) {
                setForgetPasswordResponseError(error.response.data.detail);
            }
        });
    }

    const openSendActivationModal = (event) => {
        event.preventDefault();
        sendActivationEmailModal.open();
    }

    function submitSendActivationEmail(event) {
        event.preventDefault();
        setLoader(true);
        FetchClient({
            method: 'get',
            url: 'send_activation_email/',
            params: {
                email: inputs.username
            }
        }).then(response => {
            window.$notification({
                text: 'Check your email, please. Activation email sent!',
                variant: 'success',
                position: 'center-top'
            })
            setLoader(false);
            forgetPasswordModal.close();
        }).catch((error) => {
            setLoader(false);
            if (error.response) {
                setSendActivationResponseError(error.response.data.detail);
            }
        });
    }

    useEffect(() => {
        setForgetPasswordModal(new Modal("#forgetPasswordModal"));
        setSendActivationEmailModal(new Modal("#sendActivationEmailModal"));
    }, []);

    return <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
        <main className="grid w-full grow grid-cols-1 place-items-center">
            <form
                className="w-full max-w-[26rem] p-4 sm:px-5"
                onSubmit={handleLogin}
            >
                <div className="text-center">
                    <Link to="/">
                        <svg
                            className="h-20 w-20 mx-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 500 500"
                        >
                            <g>
                                <path fill={logoColor}
                                      d="M259.1,318.6c6.2-14.9,9.2-30.9,9.2-47.8v-7.7c-0.8-6.2-1.2-12.7-1.2-19.4c0-6.5,0.4-12.9,1.2-19V120.4   c-8,5.2-15.1,11.1-21.6,17.6c-13.2,13.4-23.4,29.3-30.3,47.6c-6.9,18.3-10.5,37.8-10.5,58.6s3.5,40.3,10.5,58.6   c6.3,16.4,15.1,30.9,26.5,43.4C249.4,338,254.9,328.7,259.1,318.6z"/>
                                <path fill={logoColor}
                                      d="M206.6,246.7V272c0,20.2-5.5,36.6-16.3,49.5c-10.8,12.8-27.7,19.2-50.7,19.2c-5,0-9.1-0.2-12.5-0.7   c-1.3-0.2-2.6-0.4-3.9-0.6c1.7,5.9,2.5,12.1,2.5,18.6c0,8.3-1.4,16.1-4,23.3c-1.3,3.5-2.8,6.7-4.6,9.8v0.9   c4.3,0.6,7.9,1.2,10.7,1.7c2.8,0.5,7.5,0.7,13.8,0.7c19.3,0,36.6-3.1,52-9.7c15.3-6.4,28.4-15.1,39.2-26.1c10.8-11,19.1-24,25-39   c5.8-14.9,8.7-30.9,8.7-47.8v-25.3h-59.9V246.7z"/>
                                <g>
                                    <path fill={logoColor}
                                          d="M4.6,357.7c0-8.2,1.4-16,4-23.2c2.6-7.4,6.4-13.6,11.4-19s11.1-9.7,18.5-12.8c7.4-3.1,15.5-4.7,24.8-4.7    s17.5,1.6,24.8,4.7c7.3,3.2,13.4,7.4,18.5,12.8c5,5.4,8.8,11.8,11.4,19c2.6,7.4,4,15,4,23.2c0,8.3-1.4,16.1-4,23.3    c-2.6,7.2-6.4,13.5-11.4,18.9c-5,5.4-11.1,9.7-18.5,12.8s-15.5,4.7-24.8,4.7s-17.5-1.6-24.8-4.7c-7.3-3.1-13.4-7.4-18.5-12.8    c-5-5.4-8.8-11.7-11.4-18.9C5.8,373.8,4.6,366,4.6,357.7z M27.6,357.7c0,5.2,0.6,10.2,2,14.9c1.4,4.7,3.5,8.7,6.3,12.2    c2.8,3.5,6.6,6.2,11,8.2c4.5,2,9.9,2.9,16.2,2.9s11.7-0.9,16.2-2.9c4.5-2,8.2-4.7,11-8.2c2.8-3.5,5-7.6,6.3-12.2    c1.4-4.7,2-9.7,2-14.9s-0.6-10.2-2-14.9c-1.4-4.7-3.5-8.7-6.3-12.2c-2.8-3.5-6.6-6.2-11-8.2c-4.5-2-9.9-2.9-16.2-2.9    s-11.7,0.9-16.2,2.9s-8.2,4.7-11,8.2c-2.8,3.5-5,7.6-6.3,12.2C28.3,347.4,27.6,352.5,27.6,357.7z"/>
                                </g>
                                <path fill={logoColor} fillOpacity=".5"
                                      d="M503.4,107.4c-2.6-7.4-6.4-13.7-11.4-19c-5-5.4-11.1-9.7-18.5-12.8c-7.4-3.1-15.5-4.7-24.8-4.7   c-9.2,0-17.5,1.6-24.8,4.7c-7.4,3.2-13.4,7.4-18.5,12.8c-5,5.4-8.8,11.8-11.4,19c-2.6,7.3-4,15-4,23.2c0,8.3,1.4,16.1,4,23.3   c2.6,7.2,6.4,13.5,11.4,18.9s11.1,9.7,18.5,12.8c7.3,3.1,15.5,4.7,24.8,4.7c9.2,0,17.5-1.6,24.8-4.7c7.4-3.2,13.4-7.4,18.5-12.8   c5-5.4,8.8-11.7,11.4-18.9c2.6-7.2,4-15,4-23.3C507.3,122.4,506.1,114.6,503.4,107.4z M482.3,145.5c-1.4,4.7-3.5,8.7-6.3,12.2   c-2.8,3.5-6.6,6.2-11,8.2c-4.5,2-9.9,2.9-16.2,2.9c-6.3,0-11.7-0.9-16.2-2.9c-4.5-2-8.2-4.7-11-8.2c-2.8-3.5-5-7.6-6.3-12.2   c-1.4-4.7-2-9.7-2-14.9c0-5.3,0.6-10.2,2-14.9s3.5-8.7,6.3-12.2c2.8-3.5,6.6-6.2,11-8.2c4.5-2,9.9-2.9,16.2-2.9   c6.3,0,11.7,0.9,16.2,2.9s8.2,4.7,11,8.2c2.8,3.5,5,7.6,6.3,12.2c1.4,4.7,2,9.7,2,14.9C484.3,135.8,483.6,140.9,482.3,145.5z"/>
                                <g>
                                    <path fill={logoColor} fillOpacity=".5"
                                          d="M271.4,220.6c0.7-5.1,1.8-10.1,3.1-14.8c3.2-11.3,8.4-21.3,15.8-29.8c7.5-8.5,17.3-15.1,29.6-19.9    c12.4-4.8,27.6-7.2,45.7-7.2c8.3,0,16.5,0.3,24.7,1.1c-2.5-6.5-3.9-13.4-3.9-20.8c0-12.8,4.2-24.7,11.4-34.4    c-11.6-1.1-23.3-1.6-35.2-1.6c-24.4,0-46,3.9-65,11.8c-9.6,4-18.3,8.6-26.4,14v101.8H271.4z"/>
                                    <path fill={logoColor} fillOpacity=".5"
                                          d="M411.6,333.6c-15.1,2.4-30.5,3.6-45.9,3.6c-18.1,0-33.3-2.4-45.7-7.2s-22.3-11.4-29.6-20    c-7.5-8.5-12.7-18.5-15.8-30c-1.4-4.8-2.3-9.9-3.1-15.1v8.1c0,17-2.9,32.9-8.9,47.8c-3.8,9.5-8.6,18.2-14.5,26.1    c0.6,0.7,1.4,1.5,2.1,2.2c12.8,13.4,28.8,24.1,47.7,31.9c18.9,7.9,40.5,11.8,65,11.8c15.4,0,30.7-0.8,45.5-2.6    c14.8-1.8,28.4-4.4,40.5-8V323C439,327.7,426.7,331.1,411.6,333.6z"/>
                                </g>
                            </g>
                        </svg>
                    </Link>
                    <div className="mt-4">
                        <h2
                            className="text-2xl font-semibold text-slate-600 dark:text-navy-100"
                        >
                            JOURNiCRAFT
                        </h2>
                        <p className="text-slate-400 dark:text-navy-300">
                            Please login to continue
                        </p>
                    </div>
                </div>
                <div className="card mt-5 rounded-lg p-5 lg:p-7">
                    <label className="block">
                        <span>Username:</span>
                        <span className="relative mt-1.5 flex">
                        <input
                            className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="sample@journicraft.com"
                            name="username"
                            value={inputs.username}
                            onChange={handleChange}
                            type="text"
                        />
                        <span
                            className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 transition-colors duration-200"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                          >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                        </span>
                      </span>
                    </label>
                    <label className="mt-4 block">
                        <span>Password:</span>
                        <span className="relative mt-1.5 flex">
                        <input
                            className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Enter Password"
                            name="password"
                            value={inputs.password}
                            onChange={handleChange}
                            type={"password"}
                        />
                        <span
                            className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 transition-colors duration-200"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                          >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                        </span>
                      </span>
                    </label>
                    <div className="mt-4 flex items-center justify-between space-x-2">
                        <label className="inline-flex items-center space-x-2">
                            <input
                                className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent"
                                type="checkbox"
                            />
                            <span className="line-clamp-1">Remember me</span>
                        </label>
                        <a href="#"
                           className="text-xs text-slate-400 transition-colors line-clamp-1 hover:text-slate-800 focus:text-slate-800 dark:text-navy-300 dark:hover:text-navy-100 dark:focus:text-navy-100"
                           onClick={(event) => openForgetPasswordModal(event)}>
                            Forgot Password?
                        </a>
                    </div>
                    <button
                        className="btn mt-5 w-full bg-jclogo-400 font-medium text-white hover:bg-jclogo-600 focus:bg-primary-focus active:bg-jclogo-600/90 dark:bg-jclogo-200 dark:hover:bg-jclogo-400 dark:focus:bg-jclogo-400 dark:active:bg-jclogo-400/90"
                        type='submit'
                    >
                        Log In
                    </button>
                    {response_error.customized_error_code !== -1 ?
                        <span className="text-center text-tiny+ text-error">{response_error.message}</span>
                        : ""
                    }
                    {response_error.customized_error_code === 10001 ?
                        <strong><a
                            className="text-center cursor-pointer text-xs text-slate-400 transition-colors line-clamp-1 hover:text-slate-800 focus:text-slate-800 dark:text-navy-300 dark:hover:text-navy-100 dark:focus:text-navy-100"
                            onClick={(event) => openSendActivationModal(event)}>
                            Didn't received activation email?
                        </a></strong>
                        : ""
                    }
                    <div className="mt-4 text-center text-xs+">
                        <p className="line-clamp-1">
                            <span>Don't have Account?</span>

                            <Link to="/register"
                                  className="text-jclogo-400 transition-colors hover:text-jclogo-600 dark:text-jclogo-100 dark:hover:text-jclogo-400">
                                &nbsp;Create account
                            </Link>
                        </p>
                    </div>
                    <div className="my-7 flex items-center space-x-3">
                        <div className="h-px flex-1 bg-slate-200 dark:bg-navy-500"></div>
                        <p>OR</p>
                        <div className="h-px flex-1 bg-slate-200 dark:bg-navy-500"></div>
                    </div>
                    <div className="flex space-x-4">
                        <button
                            className="btn w-full space-x-3 border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={handleGoogleLogin}
                        >
                            <img
                                className="h-5.5 w-5.5"
                                src={googleIcon}
                                alt="logo"
                            />
                            <span>Google</span>
                        </button>
                        {/*<button*/}
                        {/*    className="btn w-full space-x-3 border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"*/}
                        {/*    onClick={(event) => event.preventDefault()}*/}
                        {/*>*/}
                        {/*    <img*/}
                        {/*        className="h-5.5 w-5.5"*/}
                        {/*        src={githubIcon}*/}
                        {/*        alt="logo"*/}
                        {/*    />*/}
                        {/*    <span>Github</span>*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div
                    className="mt-8 flex justify-center text-xs text-slate-400 dark:text-navy-300"
                >
                    <a href="#">Privacy Notice</a>
                    <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
                    <a href="#">Term of service</a>
                </div>
            </form>
        </main>

        <div
            id="forgetPasswordModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Forget Password
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Email:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Email"
                                name="email"
                                value={emailInput.name}
                                onChange={handleEmailChangeInput}
                                type={"text"}
                            />
                        </label>
                        {forget_password_response_error.customized_error_code !== -1 ?
                            <span
                                className="text-center text-tiny+ text-error">{forget_password_response_error.message}</span>
                            : ""
                        }
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={submitForgetPassword}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit Request
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="sendActivationEmailModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 dark:bg-navy-700 sm:px-5"
            >
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Send New Activation Email
                    </h2>
                    <p className="mt-2">
                        If you didn't received activation email, please first check your email spam folder. If you
                        couldn't find the email please submit another request to received the activation email.
                    </p>
                    <div className="space-x-2 text-right mt-4">
                        {send_activation_response_error.customized_error_code !== -1 ?
                            <span
                                className="text-center text-tiny+ text-error">{send_activation_response_error.message}</span>
                            : ""
                        }
                        <button
                            data-close-modal
                            onClick={submitSendActivationEmail}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-error-focus active:bg-primary-focus/90 dark:bg-primary dark:hover:bg-primary-focus dark:focus:bg-primary-focus dark:active:bg-primary/90"
                        >
                            Send Again
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LoginPage;
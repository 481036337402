import FetchClient from "../../../utils/fetchClient";
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {useLayoutContext} from "../../../layout/layoutProvider";
import {useParams} from "react-router";
import Modal from "../../../components/modal";
import { Dialog } from "@mui/material";
import { openCloseSidebarPanel } from "../../../utils/helpers";
import ReactQuill from "react-quill";

const ExercisePage = () => {
    const location = useLocation()
    const {setHeader, setLoader} = useLayoutContext();
    const params = useParams();
    const exerciseId = params.exercise_id;
    const courseId = params.course_id;
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState([]);
    const [questionInput, setQuestionInput] = useState([]);
    const [users, setUsers] = useState([]);
    const [exerciseUsers, setExerciseUsers] = useState([]);
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [selectedExerciseUser, setSelectedExerciseUser] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [modalFilteredData, setModalFilteredData] = useState([]);
    const [modalSearchValue, setModalSearchValue] = useState('');
    const [exercise, setExercise] = useState([]);
    const [modal, setModal] = useState("");
    const [showAnswersModal, setShowAnswersModal] = useState(false);
    const [questionsAnswers, setQuestionsAnswers] = useState([]);
    const [showAnswerDetail, setShowAnswerDetail] = useState(false);
    const [selectedUser, setSelecetedUser] = useState();
    const getExerciseInfo = () => {
        FetchClient({
            method: 'get',
            url: `exercises/` + exerciseId,
            params: {
                courseId: courseId
            }
        }).then(response => {
            setExercise(response.data);
            setHeader(response.data.title, response.data.type);
        })
    }
    const handleOpenAnswerModal = (question) =>{
        FetchClient({
            method: 'get',
            url: `question_answers/${question?.id}`,
            params: {
                courseId: courseId
            }
        }).then(response => {
            setQuestionsAnswers(response.data);
            setShowAnswersModal(true);

        })
        
    }
  
    const getUsers = () => {
        setLoader(true);
        FetchClient({
            method: 'get',
            url: 'course/' + courseId + '/users',
            params: {
                course_id: courseId,
            }
        }).then(response => {
            setUsers(response?.data?.items);
            getExerciseUsers();
        })
    }
    openCloseSidebarPanel(false);
    const getExerciseUsers = (page) => {
        setFilteredData([]);
        FetchClient({
            method: 'get',
            url: `exercise/` + exerciseId + '/users',
            params: {
                exercise_id: exerciseId,
                course_id : courseId
                
            }
        }).then(response => {
            setExerciseUsers(response.data.items);
        })
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumbers, setPageNumbers] = useState([]);

    const [modalCurrentPage, setModalCurrentPage] = useState(1);
    const [modalItemsPerPage, setModalItemsPerPage] = useState(10);
    const [modalPageNumbers, setModalPageNumbers] = useState([]);
  
    const handleChangeItemsPerPage = (value) => {
      setItemsPerPage(value);
      setCurrentPage(1);
      setSearchValue("")
    };
    const handleChangeModalItemsPerPage = (value) => {
        setModalItemsPerPage(value);
        setModalCurrentPage(1);
        setModalSearchValue("")
      };
      useEffect(()=>{
        const indexOfLastItem = modalCurrentPage * modalItemsPerPage;
        const indexOfFirstItem = indexOfLastItem - modalItemsPerPage;
        setModalFilteredData(users.slice(indexOfFirstItem, indexOfLastItem))
    },[modalCurrentPage])

    useEffect(()=>{
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setFilteredData(exerciseUsers.slice(indexOfFirstItem, indexOfLastItem))
    },[currentPage])

    useEffect(()=>{
        setModalPageNumbers([]); 
        for (let i = 1; i <= Math.ceil(users?.length / modalItemsPerPage); i++) {
            setModalPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
          const indexOfLastItem = modalCurrentPage * modalItemsPerPage;
          const indexOfFirstItem = indexOfLastItem - modalItemsPerPage;
          setModalFilteredData(users.slice(indexOfFirstItem, indexOfLastItem))

    },[users,modalItemsPerPage])
    useEffect(()=>{
        setPageNumbers([]); 
        for (let i = 1; i <= Math.ceil(exerciseUsers?.length / itemsPerPage); i++) {
            setPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
          const indexOfLastItem = currentPage * itemsPerPage;
          const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          if(exerciseUsers && exerciseUsers.length > 0){
          setFilteredData(exerciseUsers.slice(indexOfFirstItem, indexOfLastItem))}

    },[exerciseUsers,itemsPerPage]);

    const getQuestions = () => (
        FetchClient({
            method: 'get',
            url: `exercise_questions/` + exerciseId,
            params: {
                exercise_id: exerciseId,
                course_id: courseId
            }
        }).then(response => {
            setQuestions(response.data);
            setLoader(false);
        })
    );
    useEffect(() => {
        setLoader(true);
        getExerciseInfo();
        getUsers();
        getQuestions();
        new Modal("#addQuestionModal");
        new Modal("#addExerciseUserModal");
    }, [location]);
    useEffect(() => {
        new Modal("#addExerciseToAllUsersModal");
    }, [exerciseUsers]);
    function selectThisQuestion(event, question) {
        setModal(event)
        setSelectedQuestion(question);
        questionInput.title = question.title;
        questionInput.description = question.description;
        setQuestionInput(questionInput);
    }
    const handleQuestionChange = (event) => {
        const {name, value} = event.target;
        setQuestionInput(values => ({...values, [name]: value}))
    }
    const questionKeyUpHandler = (event, mode) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (mode === "create")
                submitQuestion(event);
            else if (mode === "update")
                updateQuestion(event);
            return false;
        }
        return true;
    };
    const submitQuestion = (event) => {
        event.preventDefault();
        FetchClient({
            method: 'post',
            url: `questions`,
            data: {
                title: questionInput.title,
                description: questionInput.description,
                exercise_id: exerciseId,
                course_id: courseId
            }
        }).then(response => {
            setQuestionInput({title: "", description: ""});
            questions.push(response.data);
            setQuestions(questions);
        });
    }
    const updateQuestion = (event) => {
        setModal("");
        setLoader(true);
        selectedQuestion.title = questionInput.title;
        selectedQuestion.description = questionInput.description;
        selectedQuestion.course_id = courseId
        FetchClient.put('questions/' + selectedQuestion.id, selectedQuestion)
            .then(response => {
                window.$notification({
                    text: 'Exercise updated successfully',
                    variant: 'primary',
                    position: 'center-top'
                });
                // setQuestionInput({title: "", description: ""});
                let response_chat = questions.findIndex((obj => obj.id === selectedQuestion.id));
                questions[response_chat].title = response.data.title;
                questions[response_chat].description = response.data.title;
                setQuestions(questions);
                setLoader(false);
            });
    }
    const handleDeleteQuestion = () => {
        setModal("");
        setLoader(true);
        FetchClient.delete('questions/' + selectedQuestion.id, {
            data: {
                question_id: selectedQuestion.id
            }
        }).then(response => {
            questions.splice(questions.findIndex(function (i) {
                return i.id === selectedQuestion.id;
            }), 1);
            setQuestions(questions);
            window.$notification({text: 'Question deleted successfully!', variant: 'error', position: 'center-top'})
            setLoader(false);
        });
    }
const [deleteExerciseUserModal, setDeleteExerciseUserModal] = useState(false);
const [restoreExerciseUserModal, setrRestoreExerciseUserModal] = useState(false);
    function selectThisExerciseUser(event, user, type) {
        event.preventDefault();
        setSelectedExerciseUser(user);
        if(type === "delete"){

            setDeleteExerciseUserModal(true);
        }
        else if(type === "restore"){

        setrRestoreExerciseUserModal(true);}

    }
    const handleDeleteExerciseUser = (event) => {
        event.preventDefault();
        setLoader(true);
        FetchClient.delete('exercise/' + exerciseId + "/user/" + selectedExerciseUser.id , {
            data: {
                exercise_id: exerciseId,
                user_id: selectedExerciseUser.id,
                course_id: courseId
            }
        }).then(response => {
            getExerciseUsers();
            setDeleteExerciseUserModal(false);
            setLoader(false);
            window.$notification({
                text: 'Exercise removed from user exercises successfully!',
                variant: 'error',
                position: 'center-top'
            })
        });
    }
    const handleRestoreExerciseUser = (event) => {
        event.preventDefault();
        setLoader(true);
        FetchClient.put('restore/exercise/' + exerciseId + "/user/" + selectedExerciseUser.id, {
            data: {
                exercise_id: exerciseId,
                user_id: selectedExerciseUser.id,
                course_id: courseId
            }
        }).then(response => {
            getExerciseUsers();
            setrRestoreExerciseUserModal(false);
            setLoader(false);
            window.$notification({
                text: 'User exercise restored successfully!',
                variant: 'primary',
                position: 'center-top'
            })
        });
    }
    const handleAssignToAll = (event) => {
        event.preventDefault();
        setSelectedUserList([]);
        for (let i = 0; i < users?.length; i++) {
            if (exerciseUsers.findIndex(function (u) {
                return users[i].id === u.id;
            }) === -1 || exerciseUsers[exerciseUsers.findIndex(function (u) {
                return users[i].id === u.id;
            })].is_exercise_deleted === true) {
                selectedUserList.push(users[i]);
            }
        }
        handleUpdateExerciseUsers(event);
    }
    const handleExerciseUserSelectChange = (event, user) => {
        const index = selectedUserList.findIndex((selectedItem) => selectedItem.id === user.id);

        if (index === -1) {
          setSelectedUserList([...selectedUserList, user]);
        } else {
          setSelectedUserList(selectedUserList.filter((selectedItem) => selectedItem.id !== user.id));
        }
    
    }
    const handleUpdateExerciseUsers = (event) => {
        event.preventDefault();
        setLoader(true);
        FetchClient({
            method: 'post',
            url: `exercise/users`,
            data: {
                userList: selectedUserList,
                exercise_id: parseInt(exerciseId),
                course_id: courseId
            }
        }).then(response => {
            setSelectedUserList([]);
            getExerciseUsers();
            setLoader(false);
            window.$notification({
                text: 'Exercise is assigned/re-assigned successfully for the selected users!',
                variant: 'info',
                position: 'center-top'
            })
        });
    }
    const searchModalUser=() =>{
        if(modalSearchValue != ""){
            setModalPageNumbers([]);      
            setModalFilteredData(users.filter(user => user.name.toLowerCase().includes(modalSearchValue.toLowerCase())));
    
        for (let i = 1; i <= Math.ceil(modalFilteredData.length / modalItemsPerPage); i++) {
            setModalPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
        }
        else{
            setModalFilteredData(users)
        }
    }
    const searchUser=() =>{
        if(searchValue != ""){
            setPageNumbers([]);      
            setFilteredData(exerciseUsers.filter(user => user.name.toLowerCase().includes(searchValue.toLowerCase())));

        for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
            setPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
        }
        else{
            setFilteredData(exerciseUsers)
        }
    }
    useEffect(()=>{
        searchModalUser()
    },[modalSearchValue])
    useEffect(()=>{
        searchUser()
    },[searchValue])
    const userItems = filteredData.map((user, index) => (
        <tr key={"user_"+user.id} className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className={"whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100 " + (user.is_exercise_deleted === true ? "line-through" : "")}>{user.name}</td>
            <td className="whitespace-nowrap justify-center px-4 py-3 sm:px-5 flex">
                <button className={`flex darkmode-toggle btn h-8 w-8 rounded-full p-1 !ml-5
                    ${user.is_exercise_deleted === true ? " hidden" : ""}`}
                    id={"delete_exercise_" + user.id}
                    onClick={(event) => selectThisExerciseUser(event, user, "delete")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" baseProfile="basic">
                        <g id="Icon-Set-Filled" transform="translate(-469.000000, -1041.000000)" fill="#c74343">
                            <path
                                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48">
                            </path>
                        </g>
                    </svg>
                </button>
                <button
                    className={`flex !ml-5 darkmode-toggle btn h-8 w-8 rounded-full p-1
                        ${user.is_exercise_deleted === false ? " hidden" : ""}`}
                    id={"restore_exercise_" + user.id}
                    onClick={(event) => selectThisExerciseUser(event, user, "restore")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" baseProfile="basic">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M5.62132 7L7.06066 8.43934C7.64645 9.02513 7.64645 9.97487 7.06066 10.5607C6.47487 11.1464 5.52513 11.1464 4.93934 10.5607L0.93934 6.56066C0.35355 5.97487 0.35355 5.02513 0.93934 4.43934L4.93934 0.43934C5.52513 -0.146447 6.47487 -0.146447 7.06066 0.43934C7.64645 1.02513 7.64645 1.97487 7.06066 2.56066L5.62132 4H10C15.5228 4 20 8.47715 20 14C20 19.5228 15.5228 24 10 24C4.47715 24 0 19.5228 0 14C0 13.1716 0.67157 12.5 1.5 12.5C2.32843 12.5 3 13.1716 3 14C3 17.866 6.13401 21 10 21C13.866 21 17 17.866 17 14C17 10.134 13.866 7 10 7H5.62132z"
                            fill="green"/>
                    </svg>
                </button>
                
            </td>
        </tr>)
    );
    const allUserItems = modalFilteredData.map((user, index) => (
        <tr key={"all_user_"+user.id} className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{user.name}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">
                { exerciseUsers.findIndex(function (i) {
                        return i.id === user.id;
                    }) === -1 ?
                        <div
                            className="badge rounded-full bg-secondary/10 text-secondary dark:bg-secondary-light/15 dark:text-secondary-light">
                            Not Assigned
                        </div>
                        :
                        exerciseUsers[exerciseUsers.findIndex(function (i) {
                            return i.id === user.id;
                        })].is_exercise_deleted === true ?
                            <div
                                className="badge rounded-full bg-error/10 text-error dark:bg-error-light/15 dark:text-error-light">
                                Deleted
                            </div>
                            :
                            <div
                                className="badge rounded-full bg-info/10 text-info dark:bg-info-light/15 dark:text-info-light">
                                Assigned
                            </div>
                }
            </td>
            <td className="whitespace-nowrap text-center justify-center  px-4 py-3 sm:px-5 flex">
                <label className={`inline-flex items-center space-x-2 mr-8 "
                     ${((exerciseUsers.findIndex(function (i) {
                        return i.id === user.id;
                    }) !== -1 && exerciseUsers[exerciseUsers.findIndex(function (i) {
                        return i.id === user.id;
                    })].is_exercise_deleted === false) ? " hidden" : "")}`}>
                    <input
                        className="form-checkbox is-outline h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                        name={"exercise_use_state_" + user.id}
                        checked={selectedUserList.some((exerciseUser) => exerciseUser.id === user.id)}
                        type="checkbox"
                        onClick={(event) => handleExerciseUserSelectChange(event, user)}
                    />
                    <p>{((exerciseUsers.findIndex(function (i) {
                        return i.id === user.id;
                    }) !== -1 && exerciseUsers[exerciseUsers.findIndex(function (i) {
                        return i.id === user.id;
                    })].is_exercise_deleted === true) ? "Re-Assign" : "Assign")}</p>
                </label>
            </td>
        </tr>)
    );

    const questionItems = questions.map((question, index) => (
        <tr onClick={()=> handleOpenAnswerModal(question)} key={"question_"+question.id} className="border-y border-transparent cursor-pointer border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{question.title}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{question.description}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{new Date(question.creation_date).toLocaleString(undefined, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: "long",
                hour: '2-digit',
                hour12: false,
                minute: '2-digit',
                second: '2-digit'
            })}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 flex">
                <button
                    className="flex darkmode-toggle btn h-8 w-8 rounded-full p-1 m-1 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    id={"edit" + question.id}
                    onClick={(event) => selectThisQuestion("edit", question)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" baseProfile="basic">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"
                              fill="orange"/>
                    </svg>
                </button>
                <button
                    className="flex darkmode-toggle btn h-8 w-8 rounded-full p-1 m-1 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    id={"delete" + question.id}
                    onClick={(event) => selectThisQuestion("delete", question)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" baseProfile="basic">
                        <g id="Icon-Set-Filled" transform="translate(-469.000000, -1041.000000)" fill="#c74343">
                            <path
                                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48">
                            </path>
                        </g>
                    </svg>
                </button>
            </td>
        </tr>)
    );

    return <main className="main-content w-full px-[var(--margin-x)] pb-8 pt-4">
        <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
                Exercise Page
            </h2>
        </div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
            <div className="col-span-12 lg:col-span-12 xl:col-span-12">
                <div className="flex items-center justify-between">
                    <h2 className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                        Exercise Questions
                    </h2>
                    <div className="flex">
                        <div className="flex items-center">
                            <button className="btn space-x-2 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                                data-toggle="modal"
                                data-target="#addQuestionModal">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="white"
                                     viewBox="0 0 28 28">
                                    <path
                                        d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21,16h-5v5 c0,0.553-0.448,1-1,1s-1-0.447-1-1v-5H9c-0.552,0-1-0.447-1-1s0.448-1,1-1h5V9c0-0.553,0.448-1,1-1s1,0.447,1,1v5h5 c0.552,0,1,0.447,1,1S21.552,16,21,16z"/>
                                </svg>
                                <span>Add a Question</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                        <table className="is-hoverable w-full text-left">
                            <thead>
                            <tr>
                                <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    #
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Title
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Description
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Creation Datetime
                                </th>
                                <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {questionItems}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 mt-12">
            <div className="col-span-12 lg:col-span-12 xl:col-span-12">
                <div className="flex flex-wrap items-center justify-between">
                    <h2
                        className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100"
                    >
                        Users who can see the exercise
                    </h2>
                    <div className="flex flex-wrap items-center">
                            <input
                                className="form-input h-10 mr-4 rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Search users"
                                name="name"
                                value={searchValue}
                                onChange={(e)=> setSearchValue(e.target.value)}
                                type={"text"}
                            />
                        <button
                            className="btn space-x-2 whitespace-nowrap bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                            data-toggle="modal"
                            data-target="#addExerciseUserModal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="white"
                                    viewBox="0 0 28 28">
                                <path
                                    d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21,16h-5v5 c0,0.553-0.448,1-1,1s-1-0.447-1-1v-5H9c-0.552,0-1-0.447-1-1s0.448-1,1-1h5V9c0-0.553,0.448-1,1-1s1,0.447,1,1v5h5 c0.552,0,1,0.447,1,1S21.552,16,21,16z"/>
                            </svg>
                            <span>Assign Exercise</span>
                        </button>
                        <button
                            className="btn ml-4 whitespace-nowrap space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                            data-toggle="modal"
                            data-target="#addExerciseToAllUsersModal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="white"
                                    viewBox="0 0 28 28">
                                <path
                                    d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21,16h-5v5 c0,0.553-0.448,1-1,1s-1-0.447-1-1v-5H9c-0.552,0-1-0.447-1-1s0.448-1,1-1h5V9c0-0.553,0.448-1,1-1s1,0.447,1,1v5h5 c0.552,0,1,0.447,1,1S21.552,16,21,16z"/>
                            </svg>
                            <span>Assign to all</span>
                        </button>
                    </div>
                </div>
                <div className="card mt-3">
                <div class="h-full border-separate overflow-clip rounded-xl border border-solid flex flex-col">
                    <table class="w-full table-fixed">
                        <thead class="sticky top-0">
                            <tr>
                                <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    #
                                </th>
                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Name
                                </th>
                                <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                    </table>
                    <div class="flex h-30 align-center  max-h-40 text-center justify-center overflow-y-auto">
                        <table class="w-full table-fixed">
                            <tbody>
                            {userItems}
                            </tbody>
                        </table>
                    </div>
                    </div>

                    <div
                        className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5"
                    >
                        <div className="flex items-center space-x-2 text-xs+">
                            <span>Show</span>
                            <label className="block">
                                <select value={itemsPerPage} onChange={(e) => handleChangeItemsPerPage(parseInt(e.target.value))}
                                        className="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
                                    <option>10</option>
                                    <option>20</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </label>
                            <span>entries</span>
                        </div>

                        <ol className="pagination">
                            <li className="rounded-l-lg bg-slate-150 dark:bg-navy-500">
                                <a
                                    href="#"
                                    onClick={(event) => (currentPage > 1 ? 
                                    setCurrentPage(currentPage - 1) : event.preventDefault())}
                                    className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 19l-7-7 7-7"
                                        />
                                    </svg>
                                </a>
                            </li>
                            {pageNumbers.map(number => (
                                <li className="bg-slate-150 dark:bg-navy-500" key={number}>
                                    <a href="#" onClick={() => setCurrentPage(number)}
                                                            className={(number != currentPage ? "flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                                    : "flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90")
                                                                }>
                                                                {number}
                                                            </a>
                                                            <a href="#"
                                                            className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                                + (((number < currentPage - 2) && (number == 1)) ? " " : " hidden")}>
                                                                {"..."}
                                                            </a> 
                                                            <a href="#"
                                                            className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                            + (((number > currentPage + 2) && (number == Math.ceil(exerciseUsers.length / itemsPerPage) - 2)) ? " " : " hidden")}>

                                                                {"..."}
                                                            </a>
                                </li>
        ))}
                            <li className="rounded-r-lg bg-slate-150 dark:bg-navy-500">
                                <a
                                    href="#"
                                    onClick={(event) => (currentPage < (Math.ceil(exerciseUsers.length / itemsPerPage) ) ? 
                                    setCurrentPage((prev)=>prev + 1) : event.preventDefault())}
                                    className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </a>
                            </li>
                        </ol>

                        <div
                            className="text-xs+">
                            {(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData?.length} entries

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="addQuestionModal"
             className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
             role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700">
                <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Add a Question
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Title:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Question Title"
                                name="title"
                                value={questionInput.title}
                                onChange={handleQuestionChange}
                                onKeyUp={(event) => questionKeyUpHandler(event, "create")}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>Description:</span>
                            <textarea
                                className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                rows="3" placeholder="Any additional description?"
                                name="description"
                                value={questionInput.description}
                                onChange={handleQuestionChange}
                            >
                            </textarea>
                        </label>
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                data-close-modal
                                onClick={submitQuestion}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Start
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Dialog open={modal === "edit"}
                onClose={()=>setModal("")}
                sx={{"& .MuiPaper-root":{width:"60%"}}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">       
                <div
                    className="modal-content relative flex w-full origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <div className="flex justify-between w-full rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                            Update the Question
                        </h3>
                        <button
                            onClick={()=> setModal("")}
                            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4.5 w-4.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Title:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Question Title"
                                    name="title"
                                    value={questionInput.title}
                                    onChange={handleQuestionChange}
                                    onKeyUp={(event) => questionKeyUpHandler(event, "update")}
                                    type={"text"}
                                />
                            </label>
                            <label className="block">
                                <span>Description:</span>
                                <textarea
                                    className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    rows="3" placeholder="Any additional description?"
                                    name="description"
                                    value={questionInput.description}
                                    onChange={handleQuestionChange}
                                >
                                </textarea>
                            </label>
                            <div className="space-x-2 text-right">
                                <button
                                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                    onClick={(event) => setModal("")}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={updateQuestion}
                                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
        </Dialog>
        <Dialog open={modal === "delete"}
                onClose={()=>setModal("")}
                sx={{"& .MuiPaper-root":{width:"60%"}}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">    
            <div
                className="modal-content scrollbar-sm relative flex flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                    alt="logo"
                />
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Delete
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to delete this question?
                    </p>
                    <div className="space-x-2 text-center mt-4">
                        <button
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setModal("")}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={handleDeleteQuestion}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
        <div id="addExerciseUserModal"
             className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
             role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-4xl origin-top flex-col overflow-hidden bg-slate-300 dark:bg-navy-900">
                <div className="flex justify-between rounded-t-lg bg-slate-300 px-4 py-3 dark:bg-navy-900 sm:px-5">
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Add users to exercise
                    </h3>
                    <input
                        className="form-input h-10 mr-4 rounded-lg border border-slate-300 px-3 py-2 placeholder:text-slate-200/70 hover:border-slate-100 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Search users"
                        name="name"
                        value={modalSearchValue}
                        onChange={(e)=> setModalSearchValue(e.target.value)}
                        type={"text"}
                    />
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div class="h-full border-separate overflow-clip rounded-xl border border-solid flex flex-col">
                    <table class="w-full table-fixed">
                        <thead class="sticky top-0">
                    <tr>
                        <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            #
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Name
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Status
                        </th>
                        <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Actions
                        </th>
                    </tr>
                    </thead>
                    </table>
                    <div class="flex text-center justify-center overflow-y-auto">
                        <table class="w-full table-fixed">
                            <tbody className="bg-slate-200 dark:bg-navy-700">
                            {allUserItems}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5"
                >
                    <div className="flex items-center space-x-2 text-xs+">
                        <span>Show</span>
                        <label className="block">
                            <select value={modalItemsPerPage}
                                    onChange={(e)=> handleChangeModalItemsPerPage(e.target.value)}
                                    className="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </label>
                        <span>entries</span>
                    </div>

                    <ol className="pagination">
                        <li className="rounded-l-lg bg-slate-150">
                            <a
                                href="#"
                                onClick={(event) => (modalCurrentPage > 1 ? 
                                setModalCurrentPage(modalCurrentPage - 1) : event.preventDefault())}                                className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                            </a>
                        </li>
                                {modalPageNumbers.map((number, index) => (
                                <li key={index} className="bg-slate-150 dark:bg-navy-500" >
                                    <a href="#" onClick={() => setModalCurrentPage(number)}
                                                            className={(number != modalCurrentPage ? "flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                                    : "flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90")
                                                                    + ((number > modalCurrentPage + 2) && (number != Math.ceil(users?.length / modalItemsPerPage) - 1) ? " hidden" : "")
                                                                    + ((number < modalCurrentPage - 2) && (number != 0) ? " hidden" : "")}>
                                                                
                                                                {number}
                                                            </a>
                                                            <a href="#"
                                                            className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                                + (((number < modalCurrentPage - 2) && (number == 1)) ? " " : " hidden")}>
                                                                {"..."}
                                                            </a> 
                                                             <a href="#"
                                                            className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                                            + (((number > modalCurrentPage + 2) && (number == Math.ceil(users.length / modalItemsPerPage) - 2)) ? " " : " hidden")}>
                                                                {"..."}
                                                            </a>
                                </li>
        ))}
                        <li className="rounded-r-lg bg-slate-150 dark:bg-navy-500">
                                <a
                                    href="#"
                                    onClick={(event) => (modalCurrentPage < (Math.ceil(users.length / modalItemsPerPage) ) ? 
                                    setModalCurrentPage((prev)=>prev + 1) : event.preventDefault())}
                                    className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </a>
                            </li>
                    </ol>

                    <div
                        className="text-xs+">
                        {(modalCurrentPage - 1) * modalItemsPerPage + 1} - {Math.min(modalCurrentPage * modalItemsPerPage, modalFilteredData.length)} of {modalFilteredData?.length} entries

                    </div>
                </div>
                <div className="bg-slate-300  dark:bg-navy-900">
                    <div className="space-x-2 text-right mt-4 mb-4 mr-4 ml-4">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => event.preventDefault()}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={handleUpdateExerciseUsers}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-primary dark:hover:bg-primary-focus dark:focus:bg-primary-focus dark:active:bg-primary/90"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
                open={deleteExerciseUserModal}
                onClose={()=>setDeleteExerciseUserModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                    alt="logo"
                />
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Delete
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to remove this exercise for the selected user?
                    </p>
                    <div className="space-x-2 text-center mt-4">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setDeleteExerciseUserModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={handleDeleteExerciseUser}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog
                open={restoreExerciseUserModal}
                onClose={()=>setrRestoreExerciseUserModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <div className="mx-auto h-16 w-16">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" baseProfile="basic">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M5.62132 7L7.06066 8.43934C7.64645 9.02513 7.64645 9.97487 7.06066 10.5607C6.47487 11.1464 5.52513 11.1464 4.93934 10.5607L0.93934 6.56066C0.35355 5.97487 0.35355 5.02513 0.93934 4.43934L4.93934 0.43934C5.52513 -0.146447 6.47487 -0.146447 7.06066 0.43934C7.64645 1.02513 7.64645 1.97487 7.06066 2.56066L5.62132 4H10C15.5228 4 20 8.47715 20 14C20 19.5228 15.5228 24 10 24C4.47715 24 0 19.5228 0 14C0 13.1716 0.67157 12.5 1.5 12.5C2.32843 12.5 3 13.1716 3 14C3 17.866 6.13401 21 10 21C13.866 21 17 17.866 17 14C17 10.134 13.866 7 10 7H5.62132z"
                              fill="green"/>
                    </svg>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Restore
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to restore this exercise for the selected user?
                    </p>
                    <div className="space-x-2 text-center mt-4">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setrRestoreExerciseUserModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={handleRestoreExerciseUser}
                            className="btn min-w-[7rem] rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90 dark:bg-green dark:hover:bg-green-focus dark:focus:bg-green-focus dark:active:bg-green/90"
                        >
                            Restore
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog open={showAnswersModal}
                onClose={()=> setShowAnswersModal(false)}
                aria-labelledby="alert-dialog-title"
                sx={{"& .MuiPaper-root":{width:"80%"}}}
                aria-describedby="alert-dialog-description">
            <div
                className="modal-content relative flex w-full origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Answers
                    </h3>
                    <button
                        onClick={()=>setShowAnswersModal(false)}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                {showAnswerDetail ?
                <>
                <span className="sm:px-5 mt-3 text-lg">{selectedUser?.user_id} answers:</span>
                <form className="scrollbar-sm overflow-y-auto sm:px-5">
                <div className="mt-4 space-y-4">
                  <label className="block">
                    <ReactQuill
                      theme="snow"
                      className="h-40 w-full inline-block"
                      value={selectedUser?.content}
                      readOnly
                      
                    />
                    <br/>
                    <span> Additional Notes: </span>
                    <textarea
                      className="form-textarea w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      rows="2"
                      placeholder="Any additional notes?"
                      name="note"
                      value={selectedUser?.note}
                      disabled
                    >
                    </textarea>
                  </label>
                </div>
                 <button 
                    onClick={()=>setShowAnswerDetail(false)}
                    className={"btn my-4 flex justify-between h-4 w-34 p-5 whitespace-nowrap bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 "}>
                      <svg width="20px" height="25px" fill="#ffffff" viewBox="0 0 24 24" id="left-arrow" xmlns="http://www.w3.org/2000/svg" class="icon line"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="primary" d="M21,12H3M6,9,3,12l3,3" 
                     stroke="#ffffff"></path></g></svg>
                        <span>
                            Go back
                        </span>
                </button>
                </form>
                </>
                :
                <div className="m-4 flex flex-col">
                    <span className="font-bold text-lg">Students who have answered:</span>
                    <ul>
                    {questionsAnswers.length > 0 && questionsAnswers.map((q, index)=>{
                        return(
                        <li onClick={()=> {setSelecetedUser(q); setShowAnswerDetail(true)}} className="cursor-pointer rounded-lg p-2 mb-2 w-1/3 border-grey-500 border-2" key={index}>{q?.name}</li>
                    )
                    })}
                    </ul>
                </div>}
            </div>

        </Dialog>
        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="addExerciseToAllUsersModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <div className="mx-auto h-16 w-16">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" baseProfile="basic">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M5.62132 7L7.06066 8.43934C7.64645 9.02513 7.64645 9.97487 7.06066 10.5607C6.47487 11.1464 5.52513 11.1464 4.93934 10.5607L0.93934 6.56066C0.35355 5.97487 0.35355 5.02513 0.93934 4.43934L4.93934 0.43934C5.52513 -0.146447 6.47487 -0.146447 7.06066 0.43934C7.64645 1.02513 7.64645 1.97487 7.06066 2.56066L5.62132 4H10C15.5228 4 20 8.47715 20 14C20 19.5228 15.5228 24 10 24C4.47715 24 0 19.5228 0 14C0 13.1716 0.67157 12.5 1.5 12.5C2.32843 12.5 3 13.1716 3 14C3 17.866 6.13401 21 10 21C13.866 21 17 17.866 17 14C17 10.134 13.866 7 10 7H5.62132z"
                              fill="green"/>
                    </svg>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Assign exercise to All
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to assign this exercise to all users?
                    </p>
                    <div className="space-x-2 text-center mt-4">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => event.preventDefault()}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={handleAssignToAll}
                            className="btn min-w-[7rem] rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-error-focus active:bg-success-focus/90 dark:bg-green dark:hover:bg-green-focus dark:focus:bg-green-focus dark:active:bg-green/90"
                        >
                            Assign to All
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </main>;
}

export default ExercisePage;